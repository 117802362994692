import { useTypedSelector } from 'store/redux';
import { getNormalizePath } from 'utils/get-normalize-path';

import styles from './user-avatar.module.css';

interface UserAvatarProps {
  onlyIcon?: boolean;
}

export function UserAvatar({ onlyIcon = false }: UserAvatarProps) {
  const { avatar, nickname, group } = useTypedSelector((state) => state.auth.data!.user);

  return (
    <div className={styles.user}>
      {avatar === null ? (
        <div className={styles.userDefaultAvatar}>{nickname[0]}</div>
      ) : (
        <img className={styles.userImage} src={getNormalizePath(avatar)} alt="avatar" />
      )}
      {!onlyIcon && (
        <div className={styles.userInfo}>
          <p className={styles.userFullName}>{nickname}</p>
          <p className={styles.userRole}>{group.title}</p>
        </div>
      )}
    </div>
  );
}
