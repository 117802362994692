import classNames from 'classnames';
import * as React from 'react';

import styles from './form-label.module.css';

interface FormLabelProps extends React.PropsWithChildren {
  label: string;
  isDisabled?: boolean;
}

export function FormLabel({ children, label, isDisabled }: FormLabelProps) {
  return (
    <label className={classNames(styles.formLabel, isDisabled && styles.formLabelDisabled)}>
      {children}
      <span className={styles.labelName}>{label}</span>
    </label>
  );
}
