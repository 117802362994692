import { useClickOutside } from 'hooks/useClickOutside';
import { ReactComponent as LinkBreakIcon } from 'assets/images/icons-24/LinkBreak.svg';

import styles from '../../table-cell.module.css';

interface TableCellListRestProps {
  items: { id: number; value: number; title: string }[];
  onClose: () => void;
  listRef: React.RefObject<HTMLDivElement>;
}

export function TableCellListRest({ items, onClose, listRef }: TableCellListRestProps) {
  useClickOutside(listRef, onClose);

  return (
    <ul className={styles.tableCellListRest}>
      {items.map(({ id, title }) => (
        <li key={id} className={styles.tableCellListRestItem}>
          <span className={styles.tableCellTitle}>{title}</span>
          <LinkBreakIcon className={styles.tableCellListRestIcon} />
        </li>
      ))}
    </ul>
  );
}
