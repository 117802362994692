import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'components/Modal/Modal';
import { TextField } from 'components/TextFiled';
import { FormLabel } from 'components/FormLabel';
import { AssignRights } from 'components/AssignRights';
import { Button } from 'components/Button';
import { getFormDataWithPermissions } from 'utils/get-form-data-with-permissions';

import styles from 'components/Modal/modal.module.css';

interface ModalAddColumnProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IFormValues {
  title: string;
  name: string;
  in_required: boolean;
  in_filter: boolean;
  is_show: boolean;
  permissions: { group_id: number; rules_id: number[] }[];
}

const validationSchema = yup.object({
  title: yup
    .string()
    .min(3, 'Поле должно содержать минимум 3 символа')
    .max(15, 'Поле должно содержать максимум 15 символов'),
  name: yup
    .string()
    .trim()
    .required('Поле обязательное')
    .matches(/^(?![0-9]).*/, 'Имя не должно начинаться с цифры')
    .matches(
      /^[a-zA-Z_$][a-zA-Z_$0-9]*$/,
      'Имя должно содержать только буквы, цифры или символы $ и _',
    ),
});

export function ModalAddColumn({ isOpen, onClose }: ModalAddColumnProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(validationSchema), defaultValues: {} });

  const onFormSubmit: SubmitHandler<IFormValues> = (data) => {
    const formData = getFormDataWithPermissions<IFormValues>(data);

    console.log(formData);
  };

  function handleModalClose() {
    onClose();
    reset();
  }

  if (!isOpen) return null;

  return (
    <Modal onClose={handleModalClose}>
      <form onSubmit={handleSubmit(onFormSubmit)} className={styles.modalForm}>
        <h2 className={styles.modalTitle}>Добавление колонки</h2>
        <div className={styles.modalSection}>
          <h3 className={styles.modalSectionTitle}>Основные данные</h3>
          <div className={styles.modalSectionColumn2}>
            <TextField
              label="Название раздела"
              placeholder="Введите текст"
              isError={Boolean(errors.title)}
              helperText={errors.title?.message}
              {...register('title')}
            />
            <TextField
              label="Системное имя (на английском)"
              placeholder="Введите текст"
              isError={Boolean(errors.name)}
              helperText={errors.name?.message}
              {...register('name')}
            />
            <select name="type">
              <option value="varchar">varchar</option>
              <option value="list">list</option>
              <option value="parent_id">parent_id</option>
            </select>
          </div>
          <div className={styles.modalChoice}>
            <FormLabel label="Обязательное поле">
              <input type="checkbox" value={1} {...register('in_required')} />
            </FormLabel>
            <FormLabel label="Включить фильтр">
              <input type="checkbox" value={1} {...register('in_filter')} />
            </FormLabel>
            <FormLabel label="Показать столбец в таблице">
              <input type="checkbox" value={1} {...register('is_show')} />
            </FormLabel>
          </div>
        </div>
        <div className={styles.modalSection}>
          <h3 className={styles.modalSectionTitle}>Назначение прав</h3>
          <AssignRights register={register} />
        </div>
        <div className={styles.modalSectionButtons}>
          <Button type="submit" isRounded>
            Добавить
          </Button>
        </div>
      </form>
    </Modal>
  );
}
