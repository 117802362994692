// TODO: разделить функцию на несколько частей
// сбор permissions, удаления false значений и т.д.

function getDataWithoutFalsy<T extends Record<string, any>>(data: T) {
  const copiedData: Record<string, any> = {};

  for (const key in data) {
    if (data[key]) {
      copiedData[key] = data[key];
    }
  }

  return copiedData;
}

export function getFormData<T extends Record<string, any>>(data: T): FormData {
  const dataWithoutFalsy = getDataWithoutFalsy(data);

  return Object.keys(dataWithoutFalsy).reduce((formData, key) => {
    formData.append(key, dataWithoutFalsy[key]);
    return formData;
  }, new FormData());
}

export function getFormDataWithPermissions<T extends Record<string, any>>(
  data: T & { permissions: { group_id: number; rules_id: number[] }[] },
) {
  const copiedData = getDataWithoutFalsy(data);

  copiedData.permissions = Object.keys(copiedData)
    .filter((key) => !Number.isNaN(+key[0]))
    .reduce((acc: { group_id: number; rules_id: number[] }[], current) => {
      delete copiedData[current];

      const [groupId, ruleId] = current.split('_');
      const permission = acc.find(({ group_id }) => group_id === +groupId);

      if (permission !== undefined) {
        permission.rules_id.push(+ruleId);
      } else {
        acc.push({ group_id: +groupId, rules_id: [+ruleId] });
      }

      return acc;
    }, []);

  return Object.keys(copiedData).reduce<FormData>((formData, key) => {
    const value = copiedData[key];

    if (typeof value === 'object') {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value as string);
    }

    return formData;
  }, new FormData());
}
