import { useState } from 'react';
import { ModalAddColumn } from 'components/ModalAddColumn';
import { Button, ButtonVariant } from 'components/Button';
import { TableCell } from 'components/TableCell';
import { TableHeaderCell } from 'components/TableHeaderCell';
import { TableCheckboxCell } from 'components/TableCheckboxCell';
import { ModalAddRow } from 'components/ModalAddRow';
import { ReactComponent as PlusIcon } from 'assets/images/icons-24/Plus.svg';
import { useGetTableHeaderRowQuery, useGetTableRowsQuery } from 'store/services/tableService';

import styles from './table.module.css';

interface TableProps {
  selectedModelName: string | undefined;
}

export function Table({ selectedModelName }: TableProps) {
  const {
    data: tableHeaderCells,
    isFetching: isFetchingTh,
    isLoading: isLoadingTh,
  } = useGetTableHeaderRowQuery(selectedModelName, {
    skip: selectedModelName === undefined,
  });
  const {
    data: tableRow,
    isFetching: isFetchingTd,
    isLoading: isLoadingTd,
  } = useGetTableRowsQuery(selectedModelName, {
    skip: selectedModelName === undefined,
  });
  const isTableFetched = isFetchingTh || isFetchingTd;
  const isTableLoading = isLoadingTh || isLoadingTd;

  console.log('header', tableHeaderCells);

  // TODO: перенести этот стейт, если будет проседать производительность
  const [isModalAddColumnOpen, setIsModalAddColumnOpen] = useState<boolean>(false);
  const [isModalAddRowOpen, setIsModalAddRowOpen] = useState<boolean>(false);

  const columnIdValue = tableHeaderCells?.find((cell) => cell.name === 'id')!.id;

  if (isTableFetched) return <h1>Loading...</h1>;

  return (
    <>
      <table className={styles.table}>
        <thead className={styles.tableHead}>
          <tr className={styles.tableRow}>
            <TableCheckboxCell />
            {tableHeaderCells?.map(
              (cell) => cell.is_show === 1 && <TableHeaderCell key={cell.id} {...cell} />,
            )}
            <td>
              <Button onlyIcon IconLeft={PlusIcon} onClick={() => setIsModalAddColumnOpen(true)} />
              <ModalAddColumn
                isOpen={isModalAddColumnOpen}
                onClose={() => setIsModalAddColumnOpen(false)}
              />
            </td>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {tableRow?.data.map((cell) => (
            // TODO: добавить еще и id колонки
            <tr key={`${columnIdValue}_${cell.id.value as number}`} className={styles.tableRow}>
              <TableCheckboxCell showOnHover />
              {tableHeaderCells?.map(
                (headerCell) =>
                  headerCell.is_show === 1 && (
                    <TableCell
                      key={`${headerCell.id}_${cell.id.value}`}
                      item_id={cell.id.value}
                      {...headerCell}
                      {...(cell[headerCell.name as keyof typeof cell] as any)}
                    />
                  ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        onClick={() => setIsModalAddRowOpen(true)}
        variant={ButtonVariant.Secondary}
        IconLeft={PlusIcon}
        isFullWidth>
        Добавить строку
      </Button>
      <ModalAddRow
        selectedModelName={selectedModelName}
        fields={tableHeaderCells}
        isOpen={isModalAddRowOpen}
        onClose={() => setIsModalAddRowOpen(false)}
      />
    </>
  );
}
