import { LoginResponse } from 'types/entities';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'store/services/authService';

const initialState: { data: LoginResponse | null } = {
  data: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }: PayloadAction<LoginResponse>) => {
        state.data = payload;
      },
    );
  },
});

export const { reducer: authReducer, actions: authActions } = authSlice;
