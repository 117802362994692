interface RoutePaths {
  table: string;
  tasks: string;
  home: string;
  login: string;
}

export const routePaths: RoutePaths = {
  home: '/',
  table: '/table',
  tasks: '/tasks',
  login: "/login",
};
