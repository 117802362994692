import { PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as CloseIcon } from 'assets/images/icons-24/X.svg';

import styles from './modal.module.css';

interface ModalProps extends PropsWithChildren {
  onClose: () => void;
}

export function Modal({ onClose, children }: ModalProps) {
  useEffect(() => {
    function handleEscapePress(e: KeyboardEvent) {
      if (e.key === 'Escape') onClose();
    }

    document.body.addEventListener('keydown', handleEscapePress);
    return () => {
      document.body.removeEventListener('keydown', handleEscapePress);
    };
  }, []);

  return createPortal(
    <div className={styles.modal}>
      <div onClick={onClose} className={styles.overlay} />
      <div className={styles.content}>
        <CloseIcon onClick={onClose} className={styles.closeIcon} />
        {children}
      </div>
    </div>,
    document.body,
  );
}
