import classNames from 'classnames';
import { UseFormRegister } from 'react-hook-form';
import { useTypedSelector } from 'store/redux';
import { Checkbox } from 'components/Checkbox';

import styles from './assign-rights.module.css';

interface AssignRightsProps {
  register: UseFormRegister<any>;
}

export function AssignRights({ register }: AssignRightsProps) {
  const groups = useTypedSelector((state) => state.auth.data!.groups);
  const rules = useTypedSelector((state) => state.auth.data!.rules);

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>
            <span className={classNames(styles.tableCell, styles.tableCellHeaderText)}></span>
          </th>
          {rules.map((rule) => (
            <th key={rule.id}>
              <div className={styles.tableCell}>{rule.title}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {groups.map((group) => (
          <tr key={group.id} className={styles.tableRow}>
            <td className={styles.tableGroupCell}>
              <span className={styles.tableCellHeaderText}>{group.title}</span>
            </td>
            {rules.map((rule) => (
              <td key={rule.id}>
                <div className={styles.tableCell}>
                  {/* <Checkbox {...register(`${group.id}_${rule.id}`)} /> */}
                  <input type="checkbox" {...register(`${group.id}_${rule.id}`)} />
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
