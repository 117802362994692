import classNames from 'classnames';
import { TableSectionSize, TableSectionVariant } from './table-section.types';
import { IModel } from 'types/entities';
import styles from './table-section.module.css';

interface TableSectionProps extends IModel {
  variant?: TableSectionVariant;
  size?: TableSectionSize;
  isSelected?: boolean;
  onClick: () => void;
}

export function TableSection({
  variant = TableSectionVariant.Primary,
  size = TableSectionSize.Large,
  title,
  isSelected = false,
  onClick,
}: TableSectionProps) {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.section, {
        [styles.sectionPrimaryVariant]: variant === TableSectionVariant.Primary,
        [styles.sectionLargeSize]: size === TableSectionSize.Large,
        [styles.sectionSelected]: isSelected,
      })}>
      {title}
    </div>
  );
}
