import { Navigate, Route, Routes } from 'react-router-dom';
import { routePaths } from '../../routes';
import { TablePage } from 'pages/table-page';
import { AuthLayout } from 'layouts/auth-layout';
import { useResetScroll } from 'hooks/useResetScroll';
import { LoginPage } from 'pages/login-page';
import { useTypedSelector } from 'store/redux';

export function AppRoutes() {
  const userData = useTypedSelector((state) => state.auth.data);
  const isAuth = userData !== null;

  // useResetScroll();

  return (
    <Routes>
      {isAuth ? (
        <Route path={routePaths.home} element={<AuthLayout />}>
          <Route path={routePaths.table} element={<TablePage />} />
          <Route path={routePaths.tasks} element={<div>tasks page</div>} />
          <Route path="*" element={<Navigate to={routePaths.table} />} />
        </Route>
      ) : (
        <>
          <Route path={routePaths.login} element={<LoginPage />} />
          <Route path="*" element={<Navigate to={routePaths.login} />} />
        </>
      )}
    </Routes>
  );
}
