import { IFullTableHeaderCell, IFullTableCell } from 'types/entities';
import { api } from './authService';

interface GetTableRowsResponse {
  data: { [key: string]: IFullTableCell }[];
}

export const tableApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTableHeaderRow: build.query<IFullTableHeaderCell[], string | undefined>({
      query: (modelName) => `/fields?model=${modelName}`,
      transformResponse: (response: IFullTableHeaderCell) => {
        const modifiedResponse = Object.values(response).map((cell) => {
          if (!cell.connect_model_list) {
            return cell;
          }

          return {
            ...cell,
            connect_model_list: cell.connect_model_list.map((item: Record<string, unknown>) => ({
              ...item,
              label: item.title,
            })),
          };
        });

        return modifiedResponse;
      },
    }),
    getTableRows: build.query<GetTableRowsResponse, string | undefined>({
      query: (modelName) => `/content/${modelName}`,
      providesTags: (result) =>
        result
          ? ([
              ...result.data.map((cell) => ({
                type: 'Table' as const,
                id: cell.id.value,
              })),
              'Table',
            ] as any)
          : ['Table'],
    }),
    createTableRow: build.mutation<void, { modelName: string | undefined; formData: FormData }>({
      query: ({ modelName, formData }) => {
        return {
          url: `/content/${modelName}`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Table'],
    }),

    editCell: build.mutation<void, FormData>({
      query: (formData) => {
        return {
          url: '/content/save/cell',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Table'],
    }),
  }),
});

export const {
  useGetTableHeaderRowQuery,
  useGetTableRowsQuery,
  useCreateTableRowMutation,
  useEditCellMutation,
} = tableApi;
