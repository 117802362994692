import { useRef, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { TableFieldFilter } from 'components/TableFieldFilter';
import { IFullTableHeaderCell } from 'types/entities';
import { ReactComponent as FadersHorizontalIcon } from 'assets/images/icons-16/FadersHorizontal.svg';
import { ReactComponent as ArrowsDownUpIcon } from 'assets/images/icons-16/ArrowsDownUp.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons-12/Plus.svg';

import styles from './table-header-cell.module.css';

export function TableHeaderCell({ title, in_filter }: IFullTableHeaderCell) {
  const [sortBy, setSortBy] = useState<'asc' | 'desc' | null>(null);

  const [isFilterOpened, setIsFilterOpened] = useState<boolean>(false);
  const filterModalRef = useRef<HTMLDivElement>(null);

  function handleSortClick() {
    const newSortValue = sortBy === null ? 'desc' : sortBy === 'desc' ? 'asc' : null;
    setSortBy(newSortValue);
  }

  return (
    <th>
      <div className={styles.tableHeaderCell}>
        <span className={styles.tableHeaderCellTitle}>{title}</span>
        <div className={styles.tabledHeaderCellDetails}>
          {in_filter === 1 && (
            <div
              ref={filterModalRef}
              className={classNames(
                styles.tabledHeaderCellDetail,
                styles.tabledHeaderCellDetailFilter,
              )}>
              <FadersHorizontalIcon onClick={() => setIsFilterOpened((is) => !is)} />
              {isFilterOpened && (
                <TableFieldFilter ref={filterModalRef} onClose={() => setIsFilterOpened(false)} />
              )}
            </div>
          )}

          <ArrowsDownUpIcon
            onClick={handleSortClick}
            className={classNames(styles.tabledHeaderCellDetail, {
              [styles.tabledHeaderCellDetail_asc]: sortBy === 'asc',
              [styles.tabledHeaderCellDetail_desc]: sortBy === 'desc',
            })}
          />
        </div>
        <Button onlyIcon className={styles.tableHeaderCellButton} IconLeft={PlusIcon} />
      </div>
    </th>
  );
}
