import { useRef, useState } from 'react';
import classNames from 'classnames';
import { TableCellListEditMode } from './TableCellListEditMode';
import { ITableCellList, ITableHeaderCellList } from 'types/entities';
import { TableCellListRest } from './TableCellListRest';
import { ReactComponent as CaretDown } from 'assets/images/icons-16/CaretDown.svg';

import styles from '../../table-cell.module.css';

type TableCellListProps = ITableHeaderCellList & ITableCellList;

export function TableCellList({ value, connect_model_list, multiple }: TableCellListProps) {
  const listRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const shownItems = value.slice(0, 3);
  const hiddenItems = value.slice(3);

  return (
    <div
      onDoubleClick={() => setIsEditMode(true)}
      className={classNames(styles.tableCell, styles.tableCellList)}>
      {isEditMode ? (
        <TableCellListEditMode
          connect_model_list={connect_model_list}
          value={value}
          multiple={multiple}
          onCloseEditMode={() => setIsEditMode(false)}
        />
      ) : (
        <>
          {shownItems.map(({ id, title }) => (
            <span key={id} className={styles.tableCellTitle}>
              {title}
            </span>
          ))}
          {!!hiddenItems.length && (
            <div ref={listRef} className={styles.tableCellTitleRestContainer}>
              <div
                onClick={() => setIsCollapsed((is) => !is)}
                className={classNames(
                  styles.tableCellTitle,
                  styles.tableCellTitleRest,
                  isCollapsed && styles.tableCellTitleRest_Collapsed,
                )}>
                <span>Еще {hiddenItems.length}</span> <CaretDown className={styles.arrowDown} />
              </div>
              {isCollapsed && (
                <TableCellListRest
                  listRef={listRef}
                  onClose={() => setIsCollapsed(false)}
                  items={hiddenItems}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
