import { RefObject } from 'react';

export function isEndOfScroll<T extends HTMLElement>(ref: RefObject<T>): boolean {
  if (ref.current === null) return false;

  const { scrollWidth, scrollLeft, clientWidth } = ref.current;
  return scrollWidth - scrollLeft - clientWidth <= 1;
}

export function isStartOfScroll<T extends HTMLElement>(ref: RefObject<T>): boolean {
  if (ref.current === null) return false;

  return ref.current.scrollLeft === 0;
}
