import { Navbar } from 'components/Navbar';
import { Outlet } from 'react-router-dom';

import styles from './auth-layout.module.css';

export function AuthLayout() {
  return (
    <div className={styles.authLayout}>
      <Navbar />
      <div className={styles.page}>
        <header className={styles.header}>HEADER</header>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
