import * as React from 'react';
import { MenuItemVariant } from './menu-item.types';
import classNames from 'classnames';

import styles from './menu-item.module.css';
import { Link } from 'react-router-dom';

interface MenuItemProps {
  varinat?: MenuItemVariant.Primary;
  onlyIcon?: boolean;
  isActive?: boolean;
  IconLeft: SVGFC;
  title?: string;
  to: string;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  varinat = MenuItemVariant.Primary,
  onlyIcon = false,
  isActive = false,
  title,
  IconLeft,
  to,
}) => {
  return (
    <Link
      to={to}
      className={classNames(styles.item, {
        [styles.itemPrimaryVariant]: varinat === MenuItemVariant.Primary,
        [styles.itemOnlyIcon]: onlyIcon,
        [styles.itemIsActive]: isActive,
      })}>
      <IconLeft className={styles.itemIcon} />
      {!onlyIcon && <span className={styles.itemTitle}>{title}</span>}
    </Link>
  );
};
