import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { tableApi } from 'store/services/tableService';
import { IFullTableCell, IFullTableHeaderCell } from 'types/entities';

// const fetchUserById = createAsyncThunk(
//   'users/fetchByIdStatus',
//   async (userId: number, thunkAPI) => {
//     const response = await userAPI.fetchById(userId);
//     return response.data;
//   },
// );

interface InitialState {
  selectedModel: string;
  columns: {
    isLoading: boolean;
    items: IFullTableHeaderCell[];
  };
  rows: {
    isLoading: boolean;
    items: IFullTableCell[];
  };
}

const initialState: InitialState = {
  selectedModel: 'string',
  columns: {
    isLoading: false,
    items: [],
  },
  rows: {
    isLoading: false,
    items: [],
  },
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setSelectedModel(state, { payload }: PayloadAction<string>) {
      state.selectedModel = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      tableApi.endpoints.getTableHeaderRow.matchFulfilled,
      (state, { payload }) => {
        console.log('payload', payload);
        state.columns.items = payload;
      },
    );
  },
});

export const { reducer: tableReducer, actions: tableActions } = tableSlice;
