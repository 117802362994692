import { useEffect, useState } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';

import styles from '../../table-cell.module.css';

interface SelectOption {
  id: number;
  value: number;
  title: string;
}

interface TableCellListEditModeProps {
  connect_model_list: SelectOption[] | null | undefined;
  value: SelectOption[] | null | undefined;
  multiple: 1 | 0 | boolean;
  onCloseEditMode: () => void;
}

export function TableCellListEditMode({
  connect_model_list,
  value,
  multiple,
  onCloseEditMode,
}: TableCellListEditModeProps) {
  const [options, setOptions] = useState<MultiValue<SelectOption>>(() =>
    (value ?? []).map((item) => ({ ...item, label: item.title })),
  );

  useEffect(() => {
    function closeOnEscapeKey(e: KeyboardEvent) {
      if (e.key === 'Escape') onCloseEditMode();
    }

    document.addEventListener('keydown', closeOnEscapeKey);

    return () => {
      document.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, []);

  function handleOptionChange(newOptions: MultiValue<SelectOption> | SingleValue<SelectOption>) {
    if (Array.isArray(newOptions)) {
      setOptions(newOptions);
    } else {
      setOptions([newOptions] as MultiValue<SelectOption>);
    }
  }

  console.log(options);

  function onSelectBlur() {}

  return (
    <Select
      onBlur={onSelectBlur}
      className={styles.select}
      placeholder="Выбрать"
      options={connect_model_list as MultiValue<SelectOption>}
      defaultValue={options}
      closeMenuOnSelect={!multiple}
      value={options}
      onChange={handleOptionChange}
      // TODO: убрать условие, как только Абдулла поправит на бек
      isMulti={Boolean(multiple) || true}
      defaultMenuIsOpen
      autoFocus
    />
  );
}
