import { forwardRef, InputHTMLAttributes, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { TextFieldSize, TextFieldVariant } from './text-field.types';
import { ReactComponent as CheckCircle } from 'assets/images/icons-24/CheckCircle.svg';
import { ReactComponent as XCircle } from 'assets/images/icons-24/XCircle.svg';
import styles from './text-field.module.css';

interface TextFieldProps extends Props<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  variant?: TextFieldVariant;
  size?: TextFieldSize;
  label?: string;
  helperText?: string;
  isFullWidth?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  IconRight?: SVGFC;
  IconLeft?: SVGFC;
  containerProps?: Props<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  containerClassName?: string;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      helperText,
      variant = TextFieldVariant.Default,
      size = TextFieldSize.Large,
      isFullWidth = false,
      isDisabled = false,
      isError = false,
      isSuccess = false,
      type = 'text',
      IconLeft,
      IconRight,
      containerProps,
      containerClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        {...containerProps}
        className={classNames(styles.textFieldContainer, containerClassName, {
          [styles.textFieldContainerFullWidth]: isFullWidth,
          [styles.textFieldContainerError]: isError,
          [styles.textFieldContainerSuccess]: isSuccess,
          [styles.textFieldContainerDisabled]: isDisabled,
        })}>
        {label !== undefined && <p className={styles.textFieldLabel}>{label}</p>}
        <div
          className={classNames(styles.inputContainer, {
            [styles.inputContainerLargeSize]: size === TextFieldSize.Large,
            [styles.inputContainerMediumSize]: size === TextFieldSize.Medium,
            [styles.inputContainerDefaultVariant]: variant === TextFieldVariant.Default,
            [styles.inputContainerIconLeftHas]: IconLeft !== undefined,
          })}>
          <input
            {...props}
            disabled={isDisabled}
            ref={ref}
            type={type}
            className={styles.textField}
          />
          {IconLeft !== undefined && (
            <IconLeft className={classNames(styles.textFieldIcon, styles.textFieldIconLeft)} />
          )}
          {IconRight !== undefined && !isSuccess && !isError && (
            <IconRight className={classNames(styles.textFieldIcon, styles.textFieldIconRight)} />
          )}
          {isSuccess && (
            <CheckCircle
              className={classNames(
                styles.textFieldIcon,
                styles.textFieldIconRight,
                styles.textFieldIconSuccess,
              )}
            />
          )}
          {isError && (
            <XCircle
              className={classNames(
                styles.textFieldIcon,
                styles.textFieldIconRight,
                styles.textFieldIconError,
              )}
            />
          )}
        </div>
        {helperText !== undefined && <p className={styles.textFieldHelperText}>{helperText}</p>}
      </div>
    );
  },
);
