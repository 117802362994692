import { LoginResponse, User } from 'types/entities';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../redux';

interface LoginRequest {
  phone: string;
  password: string;
  device_name: 'site';
}

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://crm.kod06.ru/api/v1',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.data?.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, FormData>({
      query: (credentials) => ({
        url: '/sanctum/token',
        method: 'POST',
        body: credentials,
      }),
      transformErrorResponse: (error) => {
        return (error.data as { error: string[] }).error[0];
      },
    }),
    getMe: builder.query<User, void>({
      query: () => '/user',
    }),
  }),
  tagTypes: ['Model', 'Table'],
});

export const { useLoginMutation, useGetMeQuery } = api;
