import { forwardRef, RefObject, FormEvent } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';

import styles from './table-field-filter.module.css';
import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { TextField, TextFieldSize } from 'components/TextFiled';

interface TableFieldFilterProps {
  onClose: () => void;
}

export const TableFieldFilter = forwardRef<HTMLElement, TableFieldFilterProps>(
  ({ onClose }, ref) => {
    useClickOutside(ref as RefObject<HTMLDivElement>, onClose);

    function onFormSubmit(e: FormEvent<HTMLFormElement>) {
      e.preventDefault();

      console.log('form submit');
    }

    return (
      <div className={styles.tableFieldFilter}>
        <form className={styles.form} onSubmit={onFormSubmit}>
          <TextField
            containerClassName={styles.formTextField}
            size={TextFieldSize.Medium}
            label="Содержит"
            placeholder="Введите текст"
          />
          <TextField
            containerClassName={styles.formTextField}
            size={TextFieldSize.Medium}
            label="Не содержит"
            placeholder="Введите текст"
          />
          <div className={styles.formButtons}>
            <Button className={styles.formButton} isFullWidth isRounded size={ButtonSize.Medium}>
              Применить
            </Button>
            <Button
              className={styles.formButton}
              isRounded
              size={ButtonSize.Medium}
              variant={ButtonVariant.Secondary}>
              Сброс
            </Button>
          </div>
        </form>
      </div>
    );
  },
);
