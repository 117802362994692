import { useState, forwardRef } from 'react';
import classNames from 'classnames';
import { ReactComponent as CheckboxIcon } from 'assets/images/Checkbox.svg';
import { CheckboxSize, CheckboxVariant } from './checkbox.types';
import styles from './checkbox.module.css';

interface CheckboxProps
  extends Props<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  variant?: CheckboxVariant;
  size?: CheckboxSize;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      variant = CheckboxVariant.Primary,
      size = CheckboxSize.Large,
      isChecked = undefined,
      isDisabled = false,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [checked, setChecked] = useState(false);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      setChecked(e.target.checked);

      onChange?.(e);
    }

    return (
      <div
        className={classNames(styles.checkbox, {
          [styles.checkboxPrimaryVariant]: variant === CheckboxVariant.Primary,
          [styles.checkboxLargeSize]: size === CheckboxSize.Large,
          [styles.checkboxChecked]: isChecked ?? checked,
          [styles.checkboxDisabled]: isDisabled,
        })}>
        {(isChecked ?? checked) && <CheckboxIcon className={styles.checkboxIcon} />}
        <input
          {...props}
          ref={ref}
          onChange={handleChange}
          checked={isChecked ?? checked}
          disabled={isDisabled}
          className={styles.checkboxInput}
          type="checkbox"
        />
      </div>
    );
  },
);
