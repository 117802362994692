import { RefObject, useEffect } from 'react';

export function useResizeObserver<T extends HTMLElement>(
  ref: RefObject<T>,
  cb: (element: T) => void,
) {
  useEffect(() => {
    if (ref.current === null) return;
    const element = ref.current;

    const resizeObserver = new ResizeObserver(() => cb(element));
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, []);
}
