import { ReactComponent as LogoIcon } from 'assets/images/Logo.svg';

import styles from './logo.module.css';

interface LogoProps {
  onlyIcon?: boolean;
}

export function Logo({ onlyIcon = false }: LogoProps) {
  return (
    <div className={styles.logo}>
      <LogoIcon className={styles.logoIcon} />
      {!onlyIcon && (
        <div className={styles.logoInfo}>
          <p className={styles.logoTitle}>Код будущего</p>
          <p className={styles.logoSubtitle}>it школы</p>
        </div>
      )}
    </div>
  );
}
