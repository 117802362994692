export enum ButtonVariant {
  Primary,
  Secondary,
  Clear,
}

export enum ButtonSize {
  Large,
  Medium,
}
