import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Logo } from 'components/Logo';
import { MenuItem } from 'components/MenuItem';
import { UserAvatar } from 'components/UserAvatar';
import { Button, ButtonVariant } from 'components/Button';
import { routePaths } from 'routes/routes';

import { ReactComponent as SquaresFourIcon } from 'assets/images/icons-24/SquaresFour.svg';
import { ReactComponent as TableIcon } from 'assets/images/icons-24/Table.svg';
import { ReactComponent as ChatCircleDotsIcon } from 'assets/images/icons-24/ChatCircleDots.svg';
import { ReactComponent as UsersIcon } from 'assets/images/icons-24/Users.svg';
import { ReactComponent as FolderNotchOpenIcon } from 'assets/images/icons-24/FolderNotchOpen.svg';
import { ReactComponent as ArchiveIcon } from 'assets/images/icons-24/Archive.svg';
import { ReactComponent as DiscIcon } from 'assets/images/icons-24/Disc.svg';
import { ReactComponent as SignOutIcon } from 'assets/images/icons-24/SignOut.svg';

import styles from './navbar.module.css';
import { useAppDispatch } from 'store/redux';
import { authActions } from 'store/slices/authSlice';

const menuItems = [
  { title: 'Задачи', to: routePaths.tasks, IconLeft: SquaresFourIcon },
  { title: 'Таблица', to: routePaths.table, IconLeft: TableIcon },
  { title: 'Сообщение', to: '/messages', IconLeft: ChatCircleDotsIcon },
  { title: 'Сотрудники', to: '/employees', IconLeft: UsersIcon },
  { title: 'Документы', to: '/docs', IconLeft: FolderNotchOpenIcon },
  { title: 'Архив', to: '/archive', IconLeft: ArchiveIcon },
  { title: 'Диск', to: '/disc', IconLeft: DiscIcon },
];

export function Navbar() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={classNames(styles.navbar, {
        [styles.navbarOpen]: isHovered,
      })}>
      <div className={styles.navbarLogo}>
        <Logo onlyIcon={!isHovered} />
      </div>

      <ul className={styles.navbarMenuItems}>
        {menuItems.map((menuItem, idx) => (
          <li key={idx} className={styles.navbarMenuLink}>
            <MenuItem
              onlyIcon={!isHovered}
              isActive={menuItem.to === location.pathname}
              {...menuItem}
            />
          </li>
        ))}
      </ul>

      <div className={styles.navbarDetails}>
        <UserAvatar onlyIcon={!isHovered} />
        <hr className={styles.navbarLine} />
        <Button
          onClick={() => dispatch(authActions.logout())}
          className={styles.navbarButton}
          onlyIcon={!isHovered}
          IconLeft={SignOutIcon}
          variant={ButtonVariant.Clear}>
          Выход
        </Button>
      </div>
    </div>
  );
}
