import * as React from 'react';
import { ITableCellVarchar, ITableHeaderCellVarchar } from 'types/entities';
import { ReactComponent as PencilIcon } from 'assets/images/icons-24/PencilSimple.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons-24/X.svg';
import { useEditCellMutation } from 'store/services/tableService';
import { TextField } from 'components/TextFiled';
import { getFormData } from 'utils/get-form-data-with-permissions';

import styles from '../../table-cell.module.css';

type TableCellVarcharProps = ITableHeaderCellVarchar & ITableCellVarchar;

export function TableCellVarchar({ fillable, value, item_id, id }: TableCellVarcharProps) {
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [editedValue, setEditedValue] = React.useState<string | number>(value);

  const [editCell] = useEditCellMutation();

  const onCloseEditMode = () => {
    setIsEditMode(false);
    setEditedValue(value);
  };

  const onOpenEditMode = () => {
    setIsEditMode(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      onCloseEditMode();
    }
    if (e.key === 'Enter') {
      const cellData = {
        value: editedValue,
        field_id: id,
        item_id: item_id,
        action: 'edit',
      };

      const formData = getFormData(cellData);

      editCell(formData);

      setIsEditMode(false);
    }
  };

  // TODO: уменьшить размер инпута

  return (
    <div className={styles.tableCell}>
      {isEditMode ? (
        <>
          <TextField
            autoFocus
            value={editedValue}
            onKeyDown={handleKeyDown}
            onChange={(e) => setEditedValue(e.target.value)}
          />
          <CloseIcon className={styles.closeIcon} onClick={onCloseEditMode} />
        </>
      ) : (
        <>
          <span title={`${value}`} className={styles.tableCellTitle}>
            {value}
          </span>
          {Boolean(fillable) && (
            <PencilIcon className={styles.tableCellIcon} onClick={onOpenEditMode} />
          )}
        </>
      )}
    </div>
  );
}
