import { FieldTypes } from 'types/entities';
import { TableCellVarchar } from './components/TableCellVarchar/TableCellVarchar';
import { TableCellList } from './components/TableCellList/TableCellList';
import { TableCellCheckbox } from './components/TableCellCheckbox/TableCellCheckbox';

export function getTableCellByType(type: FieldTypes) {
  switch (type) {
    case 'varchar':
    case 'text':
      return TableCellVarchar;
    case 'list':
    case 'parent_id':
      return TableCellList;
    case 'checkbox':
      return TableCellCheckbox;
  }
}
