import { Button } from 'components/Button';
import { ReactComponent as LogoIcon } from 'assets/images/loginPageLogo.svg';
import { ReactComponent as UserLogo } from 'assets/images/icons-24/User.svg';
import { ReactComponent as LockLogo } from 'assets/images/icons-24/Lock.svg';
import { ReactComponent as EyeLogo } from 'assets/images/icons-24/EyeClosed.svg';
import Gosus from 'assets/images/Frame 523.png';
import LeaderId from 'assets/images/image 1.png';
import { useLoginMutation } from 'store/services/authService';

import styles from './login-page.module.css';

export function LoginPage() {
  const [login, { isLoading, isError, error }] = useLoginMutation();
  const errorMessage = error as string;

  function onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const formData = new FormData(form);
    formData.append('device_name', 'site');

    login(formData);

    form.reset();
  }

  return (
    <div className={styles.login}>
      <div className={styles.loginBanner} />
      <div className={styles.signInSection}>
        <LogoIcon />
        <form onSubmit={onFormSubmit}>
          <div className={styles.inputLogin}>
            <UserLogo className={styles.logoEditStroke} />
            <input value="9604358578" name="phone" type="text" placeholder="Логин" />
          </div>
          <div className={styles.inputPass}>
            <LockLogo className={styles.logoEditStroke} />
            <input value="123456" name="password" type="password" placeholder="Пароль" />
            <EyeLogo className={styles.logoEditStroke} />
          </div>
          <Button type="submit" isRounded isFullWidth>
            {isLoading ? 'Загрузка...' : 'Войти'}
          </Button>

          {isError && <p className={styles.loginErrorMessage}>{errorMessage}</p>}
        </form>

        <div className={styles.sectionSignInThrough}>
          <span className={styles.sectionSignInTitle}>Вход через</span>
          <div className={styles.sectionSignInThroughIcons}>
            <img src={Gosus} alt="ГосУслуги" />
            <img src={LeaderId} alt="Leader ID" />
          </div>
        </div>
        <div className={styles.textDesc}>
          <p>
            Пользовательское соглашение Политика обработки персональных данных © 2013-2022 Все права
            защищены.
          </p>
        </div>
      </div>
    </div>
  );
}
