import { TableSectionList } from 'components/TableSectionList';
import { Table } from 'components/Table';
import { useState } from 'react';

export function TablePage() {
  const [selectedModelName, setSelectedModelName] = useState<string>();

  return (
    <>
      <TableSectionList
        selectedModelName={selectedModelName}
        setSelectedModelName={setSelectedModelName}
      />
      <Table selectedModelName={selectedModelName} />
      <p>Тут будет пагинация</p>
    </>
  );
}
