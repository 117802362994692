import { IFullTableCell, IFullTableHeaderCell } from 'types/entities';
import { getTableCellByType } from './table-cell.utils';

type TableCellProps = IFullTableCell & IFullTableHeaderCell;

export function TableCell(props: TableCellProps) {
  const Cell = getTableCellByType(props.type);

  return (
    <td>
      <Cell {...(props as any)} />
    </td>
  );
}
