import { useState } from 'react';
import classNames from 'classnames';
import { Checkbox } from 'components/Checkbox';

import styles from './table-checkbox-cell.module.css';

interface TableCheckboxCellProps {
  showOnHover?: boolean;
}

export function TableCheckboxCell({ showOnHover = false }: TableCheckboxCellProps) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <td
      className={classNames({
        [styles.tableCheckboxCellShowOnHower]: showOnHover && !isChecked,
      })}>
      <div className={styles.tableCheckboxCell}>
        <Checkbox isChecked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
      </div>
    </td>
  );
}
