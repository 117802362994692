import { IModel } from 'types/entities';
import { api } from './authService';

export const modelApi = api.injectEndpoints({
  endpoints: (build) => ({
    getModels: build.query<IModel[], void>({
      query: () => '/models',
      transformResponse: (response: { data: IModel[] }) => {
        return response.data;
      },
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'Model' as const, id })), 'Model'] : ['Model'],
    }),
    createModel: build.mutation<void, FormData>({
      query: (formData) => ({
        url: '/models',
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Model'],
    }),
  }),
});

export const { useGetModelsQuery, useLazyGetModelsQuery, useCreateModelMutation } = modelApi;
