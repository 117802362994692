import * as yup from 'yup';
// TODO: реализовать этот хук
import { useFormWithSchema } from 'hooks/useFormWithSchema';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Checkbox } from 'components/Checkbox';
import { Modal } from 'components/Modal';
import { TextField } from 'components/TextFiled';
import { AssignRights } from 'components/AssignRights';
import { FormLabel } from 'components/FormLabel';
import { Button } from 'components/Button';
import { getFormDataWithPermissions } from 'utils/get-form-data-with-permissions';
import { useCreateModelMutation } from 'store/services/modelService';

import styles from 'components/Modal/modal.module.css';

interface ModalAddTableProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IFormValues {
  title: string;
  title2: string;
  name: string;
  is_public: boolean;
  in_menu: boolean;
  permissions: { group_id: number; rules_id: number[] }[];
}
// TODO: если поле name уже существует, то надо выкинуть ошибку
const validationSchema = yup.object({
  title: yup
    .string()
    .min(3, 'Поле должно содержать минимум 3 символа')
    .max(15, 'Поле должно содержать максимум 15 символов'),
  title2: yup
    .string()
    .min(3, 'Поле должно содержать минимум 3 символа')
    .max(15, 'Поле должно содержать максимум 15 символов'),
  name: yup
    .string()
    .trim()
    .required('Поле обязательное')
    .matches(/^(?![0-9]).*/, 'Имя не должно начинаться с цифры')
    .matches(
      /^[a-zA-Z_$][a-zA-Z_$0-9]*$/,
      'Имя должно содержать только буквы, цифры или символы $ и _',
    ),
});

export function ModalAddTable({ isOpen, onClose }: ModalAddTableProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(validationSchema), defaultValues: {} });

  const [createModel, { isError, isLoading, isSuccess }] = useCreateModelMutation();

  const onFormSubmit: SubmitHandler<IFormValues> = (data) => {
    const formData = getFormDataWithPermissions<IFormValues>(data);

    // TODO: добавление модели
    createModel(formData)
      .unwrap()
      .then(() => reset());
  };

  function handleModalClose() {
    onClose();
    reset();
  }

  if (!isOpen) return null;

  return (
    <Modal onClose={handleModalClose}>
      <form onSubmit={handleSubmit(onFormSubmit)} className={styles.modalForm}>
        <h2 className={styles.modalTitle}>Добавление таблицы</h2>
        <div className={styles.modalSection}>
          <h3 className={styles.modalSectionTitle}>Основные данные</h3>
          <div className={styles.modalSectionColumn2}>
            <TextField
              label="Название раздела"
              placeholder="Введите текст"
              isError={Boolean(errors.title)}
              helperText={errors.title?.message}
              {...register('title')}
            />
            <TextField
              label="Название в винительном падеже"
              placeholder="Введите текст"
              isError={Boolean(errors.title2)}
              helperText={errors.title2?.message}
              {...register('title2')}
            />
            <TextField
              label="Системное имя (на английском)"
              placeholder="Введите текст"
              isError={Boolean(errors.name)}
              helperText={errors.name?.message}
              {...register('name')}
            />
          </div>
          <div className={styles.modalChoice}>
            <FormLabel label="Видно всем">
              {/* <Checkbox value={1} {...register('is_public')} /> */}
              <input type="checkbox" value={1} {...register('is_public')} />
            </FormLabel>
            <FormLabel label="Показать в меню">
              {/* <Checkbox value={1} {...register('in_menu')} /> */}
              <input type="checkbox" value={1} {...register('in_menu')} />
            </FormLabel>
          </div>
        </div>
        <div className={styles.modalSection}>
          <h3 className={styles.modalSectionTitle}>Назначение прав</h3>
          <AssignRights register={register} />
        </div>
        <div className={styles.modalSectionButtons}>
          <Button type="submit" isRounded>
            Добавить
          </Button>
        </div>
        {isLoading && <p>Загрузка...</p>}
        {isSuccess && <p>Успех</p>}
        {isError && <p>Провал</p>}
      </form>
    </Modal>
  );
}
