import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'components/Modal/Modal';
import { TextField } from 'components/TextFiled';
import { FormLabel } from 'components/FormLabel';
import { Button } from 'components/Button';
import { IFullTableHeaderCell } from 'types/entities';

import styles from 'components/Modal/modal.module.css';
import { useCreateTableRowMutation } from 'store/services/tableService';
import { getFormData } from 'utils/get-form-data-with-permissions';

interface ModalAddRowProps {
  isOpen: boolean;
  onClose: () => void;
  fields?: IFullTableHeaderCell[];
  selectedModelName: string | undefined;
}

interface IFormValues {
  [index: string]: string;
}

export function ModalAddRow({ isOpen, onClose, fields, selectedModelName }: ModalAddRowProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormValues>();

  const [createTableRow] = useCreateTableRowMutation();

  function handleModalClose() {
    onClose();
    reset();
  }

  const onFormSubmit: SubmitHandler<IFormValues> = (data) => {
    const formData = getFormData<IFormValues>(data);

    createTableRow({ modelName: selectedModelName, formData });

    handleModalClose();
  };

  function renderField(field: IFullTableHeaderCell) {
    if (!field.fillable) return null;
    switch (field.type) {
      case 'varchar':
      case 'text':
        return (
          <TextField
            key={field.id}
            label={field.title}
            placeholder="Введите текст"
            isError={Boolean(errors[field.name])}
            helperText={errors[field.name] !== undefined ? 'Поле обязательное' : undefined}
            {...register(field.name, { required: true })}
          />
        );
      case 'list':
        return (
          <div key={field.id}>
            <p>{field.title}</p>
            <select
              defaultValue=""
              style={{ width: '100%' }}
              {...register(field.name, { required: true })}>
              <option disabled value="">
                Выбрать
              </option>
              {field.connect_model_list?.map(({ id, value, title }) => (
                <option key={id} value={value}>
                  {title}
                </option>
              ))}
            </select>
            {errors[field.name] !== undefined && (
              <p style={{ color: 'var(--danger-600)' }}>Поле обязательное</p>
            )}
          </div>
        );
      case 'checkbox':
        return (
          <FormLabel label={field.title} key={field.id}>
            <input type="checkbox" value={1} {...register(field.name)} />
          </FormLabel>
        );
    }
  }

  if (!isOpen) return null;

  return (
    <Modal onClose={handleModalClose}>
      <form onSubmit={handleSubmit(onFormSubmit)} className={styles.modalForm}>
        <h2 className={styles.modalTitle}>Добавление строки</h2>
        <div className={styles.modalSection}>
          <h3 className={styles.modalSectionTitle}>Основные данные</h3>
          <div className={styles.modalSectionColumn3}>
            {fields?.map((field) => renderField(field))}
          </div>
        </div>
        <div className={styles.modalSectionButtons}>
          <Button type="submit" isRounded>
            Добавить
          </Button>
        </div>
      </form>
    </Modal>
  );
}
