import { ITableCellCheckbox, ITableHeaderCellCheckbox } from 'types/entities';
import styles from '../../table-cell.module.css';

type TableCellCheckboxProps = ITableHeaderCellCheckbox & ITableCellCheckbox;

export function TableCellCheckbox({ type, value }: TableCellCheckboxProps) {
  return (
    <div className={styles.tableCell}>
      <span className={styles.tableCellTitle}>{Boolean(value) ? 'Да' : 'Нет'}</span>
    </div>
  );
}
