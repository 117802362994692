import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { TableSection } from 'components/TableSection/TableSection';
import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { ReactComponent as CaretLeftIcon } from 'assets/images/icons-24/CaretLeft.svg';
import { ReactComponent as CaretRightIcon } from 'assets/images/icons-24/CaretRight.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons-24/Plus.svg';

import { useResizeObserver } from 'hooks/useResizeObserver';
import { isEndOfScroll, isStartOfScroll } from './table-section-list.utils';
import { useLazyGetModelsQuery } from 'store/services/modelService';
import { ModalAddTable } from 'components/ModalAddTable';

import styles from './table-section-list.module.css';

interface TableSectionListProps {
  selectedModelName: string | undefined;
  setSelectedModelName: (modelName: string) => void;
}

export function TableSectionList({
  selectedModelName,
  setSelectedModelName,
}: TableSectionListProps) {
  const [getModels, { data: models = [] }] = useLazyGetModelsQuery();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [scrolledItemIdx, setScrolledItemIdx] = useState<number>(0);
  const [isArrowsVisible, setIsArrowsVisible] = useState<boolean>(false);
  const sectionListRef = useRef<HTMLDivElement>(null);

  const [isLeftButtonDisabled, setIsLeftButtonDisabled] = useState<boolean>(false);
  const [isRightButtonDisabled, setIsRightButtonDisabled] = useState<boolean>(false);

  useResizeObserver(sectionListRef, (sectionListElement) => {
    setIsLeftButtonDisabled(isStartOfScroll(sectionListRef));
    setIsRightButtonDisabled(isEndOfScroll(sectionListRef));

    const { scrollWidth, offsetWidth } = sectionListElement;
    setIsArrowsVisible(scrollWidth > offsetWidth);
  });

  useEffect(() => {
    getModels()
      .unwrap()
      .then((data) => setSelectedModelName(data[0].name));

    if (sectionListRef.current === null) return;

    function scrollHandler(e: WheelEvent) {
      if (e.deltaY < 0) {
        setScrolledItemIdx((prev) => Math.max(0, prev - 1));
      }

      if (e.deltaY > 0) {
        if (!isEndOfScroll(sectionListRef)) {
          setScrolledItemIdx((prev) => prev + 1);
        }
      }
    }

    sectionListRef.current.addEventListener('wheel', scrollHandler);

    return () => {
      sectionListRef.current?.removeEventListener('wheel', scrollHandler);
    };
  }, []);

  useEffect(() => {
    if (sectionListRef.current == null) return;

    const scrolledItem = sectionListRef.current.childNodes[scrolledItemIdx] as HTMLDivElement;
    if (scrolledItem) sectionListRef.current.scroll(scrolledItem.offsetLeft, 0);

    setIsLeftButtonDisabled(isStartOfScroll(sectionListRef));
    setIsRightButtonDisabled(isEndOfScroll(sectionListRef));
  }, [scrolledItemIdx]);

  function onLeftClick() {
    setScrolledItemIdx((prev) => prev - 1);
  }

  function onRightClick() {
    setScrolledItemIdx((prev) => prev + 1);
  }

  return (
    <div className={styles.sections}>
      <ModalAddTable onClose={() => setIsModalOpen(false)} isOpen={isModalOpen} />
      <div ref={sectionListRef} className={styles.sectionList}>
        {models
          .filter(({ in_menu }) => in_menu === 1)
          .map((model) => (
            <TableSection
              key={model.id}
              isSelected={selectedModelName === model.name}
              onClick={() => setSelectedModelName(model.name)}
              {...model}
            />
          ))}
      </div>
      {isArrowsVisible && (
        <>
          <Button
            onClick={onLeftClick}
            className={styles.sectionButton}
            variant={ButtonVariant.Clear}
            size={ButtonSize.Medium}
            IconLeft={CaretLeftIcon}
            isDisabled={isLeftButtonDisabled}
            onlyIcon
          />
          <Button
            onClick={onRightClick}
            className={styles.sectionButton}
            variant={ButtonVariant.Clear}
            size={ButtonSize.Medium}
            IconLeft={CaretRightIcon}
            isDisabled={isRightButtonDisabled}
            onlyIcon
          />
        </>
      )}
      <Button
        onClick={() => setIsModalOpen(true)}
        className={classNames(styles.sectionButton, styles.sectionButtonPlus)}
        IconLeft={PlusIcon}
        size={ButtonSize.Medium}
        onlyIcon
      />
    </div>
  );
}
